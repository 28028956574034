<template>
  <div class="myDonateDetail">
    <div class="oItem">
      <div class="oItem-head">
        <div class="oItem-headName">
          <div class="oItem-avatar">
            <img
              :src="
                oItemDetail.avatar
                  ? oItemDetail.avatar
                  : require('@/assets/img/default_avatar.png')
              "
              alt=""
            />
          </div>
          <div class="oItem-wrap">
            <div class="oItem-tl oItem-name">
              {{
                oItemDetail.donateUserName
                  ? formatStrFunc(oItemDetail.donateUserName)
                  : ""
              }}
            </div>
            <div class="oItem-t">
              <div class="oItem-tr oItem-time">
                {{ oItemDetail.insertTime }}
              </div>
              <div class="oItem-tr oItem-time">
                {{ oItemDetail.bonusPointsText }}
              </div>
            </div>
          </div>
          <div
            class="oItem-icon"
            v-if="getImgUrl(oItemDetail) && oItemDetail.cancelStatus == 0"
          >
            <img
              :src="require(`@/assets/img/${getImgUrl(oItemDetail)}.png`)"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="oItem-b">
        <div class="oitem-b_tag">
          <span v-show="getStatus(oItemDetail)">
            #{{ getStatus(oItemDetail) }}#
          </span>
        </div>
        <div class="oItem-b_text">
          <span>{{ oItemDetail.donateContent }}</span>
        </div>
      </div>
    </div>
    <div class="img" v-if="oItemDetail.donatePic">
      <img :src="oItemDetail.donatePic" alt="" />
    </div>
    <div
      class="cancel"
      v-if="oItemDetail.cancelStatus != 1 && oItemDetail.confirmStatus != 1"
      @click="cancel"
    >
      取消捐赠
    </div>
    <v-dialog v-model="isDialog" title="请输入取消捐赠理由" @confirm="confirm"
      ><v-input type="textarea" v-model="reasons"
    /></v-dialog>
  </div>
</template>

<script>
import { myDonateDetailUrl, cancelUrl } from "./api.js";
import { getOption, formatStr } from "@/utils/utils.js";
import { auditStatusMap } from "./map.js";
var moment = require("moment");
export default {
  name: "myDonateDetail",
  data() {
    return {
      oItemDetail: {},
      id: "",
      isDialog: false,
      reasons: "",
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
  },
  mounted() {
    this.getWishDetail();
  },
  created() {
    this.id = this.$route.query.id;
  },

  methods: {
    getImgUrl(item) {
      const imgUrlMap = [
        { key: 1, value: "out" },
        { key: 2, value: "reject" },
        { key: 0, value: "waitVerify" },
      ];
      if (item.auditStatus || item.auditStatus == 0) {
        return getOption(item.auditStatus, imgUrlMap, "key").value;
      } else {
        return "";
      }
    },
    formatStrFunc(str) {
      if (str) {
        return formatStr(str);
      }
    },
    getStatus(item) {
      if (item.wishType) {
        return getOption(item.wishType, auditStatusMap, "key").value;
      } else {
        return "";
      }
    },
    getWishDetail() {
      let params = {
        donateId: this.id,
        userId: this.userId,
      };
      this.$axios.get(`${myDonateDetailUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          this.oItemDetail = res.data;
          if (this.oItemDetail.donateUserPic) {
            this.oItemDetail.donateUserPic =
              this.oItemDetail.donateUserPic.ossResize(80);
          }
          this.oItemDetail.bonusPointsText = "";
          if (this.oItemDetail.auditStatus == 1) {
            if (this.oItemDetail.confirmStatus == 1) {
              this.oItemDetail.bonusPointsText =
                "已获得：" + this.oItemDetail.bonusPoints + "分";
            } else {
              this.oItemDetail.bonusPointsText =
                "完成捐赠可获得：" + this.oItemDetail.bonusPoints + "分";
            }
          }
        } else {
        }
      });
    },
    confirm() {
      if (!this.reasons) {
        this.$toast("请输入理由");
        this.isDialog = true;
        return;
      }
      this.isDialog = false;
      let params = {
        donateId: this.id,
        userId: this.userId,
        myDonateCancel: this.reasons,
      };
      this.$axios.get(`${cancelUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          this.$toast("取消成功");
          // this.timer = setTimeout(() => {
          //   this.$router.replace({
          //     name: "myDonate",
          //   });
          // }, 200);
          this.$router.replace({
            name: "myDonate",
          });
        } else {
          this.$toast(`${res.msg}`);
        }
      });
    },
    cancel() {
      this.isDialog = true;
    },
  },
};
</script>
<style lang="less" scoped>
.myDonateDetail {
  height: 100vh;
  .oItem {
    background: #fff;
    padding: 32px;
    .oItem-head {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      margin-bottom: 10px;
      align-items: center;
      width: 100%;
      .oItem-avatar {
        width: 72px;
        height: 72px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .oItem-headName {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        .oItem-wrap {
          flex: 1;
          padding: 0 0 0 16px;
          .oItem-name {
            font-size: 28px;
            font-weight: bold;
            color: #333333;
            line-height: 40px;
          }
          .oItem-t {
            margin-top: 4px;
            font-size: 24px;
            font-weight: 400;
            color: #97999b;
            line-height: 36px;
            .oItem-time {
              font-size: 28px !important;
              font-weight: normal !important;
              color: #999999;
            }
          }
        }
        .oItem-icon {
          position: absolute;
          right: 0;
          top: -32px;
          img {
            height: 164px;
          }
        }
      }
    }
    .oItem-b {
      font-size: 32px;
      line-height: 44px;
      .oitem-b_tag {
        font-size: 32px;
        font-weight: 400;
        color: #fe6f16;
        margin-top: 16px;
      }
      .oItem-b_text {
        font-size: 32px;
        font-weight: 400;
        color: #333333;
        margin-top: 8px;
      }
    }
  }
  .cancel {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    width: 100%;
    height: 96px;
    background: #178af7;
    text-align: center;
    font-size: 32px;
    line-height: 96px;
    color: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  }
  .img {
    padding: 0px 40px;
    img {
      width: 100%;
      border-radius: 10px;
    }
  }
}
</style>
<style lang="less">
.myDonateDetail {
  .van-field__body {
    border: 1px solid #e9e9e9;
  }
}
</style>
